import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {BacnetDataPoint} from "../../../../domain/device.interface";
import {MatSort} from "@angular/material/sort";
import {BacnetDeviceService} from "../../../../services/bacnet-device.service";
import {NotificationService} from "../../../../services/notification.service";
import {BacnetDataPointAPI, BacnetDataPointConfig} from "../../../../domain/bacnet-datapoint";
import {interval, Subscription} from "rxjs";
import {BacnetDevice} from "../../../../domain/bacnet-device";

const PENDING_ADD_STATUS = "PENDING_ADD";
const PENDING_UPDATE_STATUS = "PENDING_UPDATE";
const PENDING_DELETE_STATUS = "PENDING_DELETE";
const DEPLOYED_STATUS = "DEPLOYED";
const UNDEPLOYED_STATUS = "UNDEPLOYED";

@Component({
  selector: 'eis-gateway-bacnet-datapoint-configurator-table',
  templateUrl: './bacnet-datapoint-configurator-table.component.html',
  styleUrls: ['./bacnet-datapoint-configurator-table.component.scss']
})
export class BacnetDatapointConfiguratorTableComponent implements OnChanges, AfterViewInit, OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  public gatewaySerial: string

  @Input()
  device: BacnetDevice | null;


  @Output()
  closeDrawerEvent: EventEmitter<void> = new EventEmitter();

  public scanningDeviceDataPoints: boolean;
  public dataPointsDataSource = new MatTableDataSource<BacnetDataPoint>([]);
  public displayedColumns: string[] = ['select', 'key', 'dataType', 'value', 'unit', 'description', 'pollingIntervalSeconds']

  public scanDatapointsSubscription: Subscription;


  constructor(private bacnetService: BacnetDeviceService,
              private notificationService: NotificationService,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.getDatapoints()
    }
  }


  ngAfterViewInit(): void {
    this.dataPointsDataSource.sort = this.sort;
  }

  ngOnInit(): void {
  }


  getDatapoints() {
    if (this.device == null) {
      return
    }

    this.dataPointsDataSource.data = []
    this.bacnetService.getDatapointScanResult(this.gatewaySerial, this.device.id.toString())
      .subscribe(result => {

        if (result.scanStatus !== 'done') {
          this.scanningDeviceDataPoints = true
        }

        this.bacnetService.getDatapointConfigList(this.device!!.id.toString())
          .subscribe(datapointCOnfigList => {

            this.dataPointsDataSource.data = result.datapointList.map(datapoint => {
              let config = datapointCOnfigList.find(it => it.datapointId == datapoint.id)?.deploymentStatus ?? UNDEPLOYED_STATUS;
              return this.toCoreModel(datapoint, config)
            })
            this.dataPointsDataSource.sort = this.sort;


          })


        //hide spinner
        this.scanningDeviceDataPoints = false
        if (this.scanDatapointsSubscription) {
          this.scanDatapointsSubscription.unsubscribe()
        }

      })
  }

  private toCoreModel(it: BacnetDataPointAPI, config: string): BacnetDataPoint {
    return {
      id: it.id,
      dataType: it.objectName,
      key: it.type + '_' + it.instance,
      value: it.presentValue,
      unit: it.units,
      description: it.description,
      deploymentStatus: config,
      pollingIntervalSeconds: it.pollingIntervalSeconds

    }
  }

  startScanFOrDatapoints() {
    this.scanningDeviceDataPoints = true
    this.bacnetService.startScanDeviceDatapoints("assethub_dev", this.gatewaySerial, this.device!!.id.toString())//todo tenant
      .subscribe(result => {
          console.log("result:")
          console.log(result)
          if (result) {
            this.scanDatapointsSubscription = interval(5 * 1000).subscribe(_ => this.getDatapoints());

          }

        },
        error => {
          console.log("error:", error)
          this.notificationService.failure("Error, please check the logs")
        }
      )
  }

  toggleAllRows() { //todo

  }

  isAllSelected() { //todo
    return false;
  }


  toggleRowSelected(row: BacnetDataPoint) {
    row.deploymentStatus = this.isSelected(row) ? PENDING_DELETE_STATUS : PENDING_ADD_STATUS
  }


  isSelected(row: BacnetDataPoint) {
    return [PENDING_ADD_STATUS, PENDING_UPDATE_STATUS, DEPLOYED_STATUS].includes(row.deploymentStatus)
  }

  isDeleted(row: BacnetDataPoint) {
    return PENDING_DELETE_STATUS === row.deploymentStatus
  }

  isNew(row: BacnetDataPoint) {
    return [PENDING_ADD_STATUS].includes(row.deploymentStatus)
  }

  closeDrawer() {
    this.closeDrawerEvent.emit()
  }


  saveChanges() {

    let newConfigList: BacnetDataPointConfig[] = this.dataPointsDataSource.data.map(row => {

        return {
          deviceId: this.device!!.id,
          datapointId: row.id,
          deploymentStatus: row.deploymentStatus,
        }
      }
    )


    let request = {
      addList: newConfigList.filter(config => config.deploymentStatus === PENDING_ADD_STATUS),
      updateList: newConfigList.filter(config => config.deploymentStatus === PENDING_UPDATE_STATUS),
      deleteList: newConfigList.filter(config => config.deploymentStatus === PENDING_DELETE_STATUS),

    }

    this.bacnetService.saveDatapointConfigList(request).subscribe(async result => {
      if (result) {
        this.closeDrawer()
        await this.notificationService.success("bacnet-preview-changes.apply.success")
      }
    })


  }


}
