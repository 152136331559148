import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {triggerLoader} from '../components/decorators/loaderDecorator';
import {checkFullyConnected} from '../components/decorators/checkConnectedDecorator';
import {BacnetDevice} from '../domain/bacnet-device';
import {BacnetDatapointsScanResult, BacnetScanResult} from '../domain/bacnet-scan-result';
import {BacnetDataPointConfig} from "../domain/bacnet-datapoint";

@Injectable({
  providedIn: 'root'
})
export class BacnetDeviceService {
  private readonly SERVICE_URL = `${environment.backendUrl}/bacnet`;

  constructor(private http: HttpClient) {
  }

  public getAllDevices(gatewaySerial?: string): Observable<BacnetDevice[]> {
    return this.http.get<BacnetDevice[]>(this.SERVICE_URL + '/' + gatewaySerial);
  }

  @triggerLoader
  @checkFullyConnected
  addDevice(device: BacnetDevice) {
    return this.http.post<BacnetDevice>(this.SERVICE_URL, device);
  }

  updateDevice(device: BacnetDevice) {
    return this.http.put<BacnetDevice>(this.SERVICE_URL, device);
  }

  deleteDevice(device: BacnetDevice, deleteInEoit: boolean) {
    return this.http.delete(this.SERVICE_URL + '/' + device.id + "?deleteInEiot=" + deleteInEoit);
  }

  startScanDevice(gatewaySerial: string, timeOut: number) {
    return this.http.post<boolean>(this.SERVICE_URL + '/start-scan/' + gatewaySerial + '?timeOut=' + timeOut, {});
  }

  startScanDeviceDatapoints(tenant: string, gatewaySerial: string, deviceId: string,) {

    let params = new HttpParams();
    params = params.set('gatewaySerial', gatewaySerial);
    params = params.set('tenant', tenant);
    return this.http.post<boolean>(this.SERVICE_URL + `/scan-datapoints/devices/${deviceId}/start-scan-datapoints`, {}, {params});
  }

  getScanResult(gatewaySerial: string) {
    return this.http.post<BacnetScanResult>(this.SERVICE_URL + '/scan-result/' + gatewaySerial, {});
  }

  saveDatapointConfigList(payload: any) {
    return this.http.put<BacnetScanResult>(this.SERVICE_URL + '/datapoint-config/save-datapoint-configs', payload);
  }

  getDatapointScanResult(gatewaySerial: string, deviceId: string) {
    let params = new HttpParams();
    params = params.set('gatewaySerial', gatewaySerial);
    return this.http.post<BacnetDatapointsScanResult>(this.SERVICE_URL + `/scan-datapoints/devices/${deviceId}/scan-datapoints-result` ,
      {}, {params});
  }

  getDatapointConfigList(deviceId: string) {
    return this.http.get<BacnetDataPointConfig[]>(this.SERVICE_URL + `/datapoint-config/devices/${deviceId}/find-all-datapoint-configs` ,
      {}, );
  }
}
