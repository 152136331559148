import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BacnetDeviceService} from "../../../../services/bacnet-device.service";
import {BacnetDevice} from "../../../../domain/bacnet-device";
import {MatDrawer} from "@angular/material/sidenav";

@Component({
  selector: 'eis-gateway-bacnet-device-configurator',
  templateUrl: './bacnet-device-configurator.component.html',
  styleUrls: ['./bacnet-device-configurator.component.scss']
})
export class BacnetDeviceConfiguratorComponent implements OnInit {

  @Input()
  public gatewaySerial: string;

  @ViewChild('drawer') drawer: MatDrawer

  public selectedDevice: BacnetDevice|null;


  constructor(
    private bacnetService: BacnetDeviceService,
  ) {
  }

  ngOnInit(): void {
  }


  async deviceSelectedEventHandler(selectedDevice: BacnetDevice) {
    this.selectedDevice = selectedDevice;
    await this.drawer.open()
  }

 async   closeDrawerEventHandler($event: void) {
  await  this.drawer.toggle()
   this.selectedDevice=null
  }
}
