<div fxLayout="column" class="main-container">
  <div class="device-header-container">
    <div class="action-container">
      <div class="flex-row-space-between-no-wrap">
         <eon-ui-button [text]="'mbus-device-table.buttons.scan' | translate" *ngIf="!scanningDevices"
                            icon="search" scheme="red"
                            (click)="scanBacnetDevices()">
       </eon-ui-button>
       </div>

      <eon-ui-button [text]="'mbus-device-table.buttons.preview-changes' | translate" *ngIf="hasChanges()"
                     icon="meter_read_no_read"
                     scheme="red"
                     rank="secondary"
                     (click)="previewUndeployedChanges()"></eon-ui-button>
      </div>
  </div>

  <div *ngIf="scanningDevices" class="warning-container">
    <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
    <span>{{'bacnet-device-table.warning.scan-take-long' | translate}}</span>
  </div>

  <div class="table-container">
    <table mat-table matSort [dataSource]="devicesDataSource">

      <ng-container matColumnDef="hasUndeployedDataPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'mbus-device-table.header.has-undeployed-data-points' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">
          <div *ngIf="device.hasUndeployedDataPoints">
            <mat-icon class="red-point">circle</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deviceAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bacnet-device-table.header.device-address' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{device.deviceAddress}}</td>
      </ng-container>

      <ng-container matColumnDef="deviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bacnet-device-table.header.device-id' | translate}}</th>
        <td mat-cell *matCellDef="let device">{{device.deviceId}}</td>
      </ng-container>

      <ng-container matColumnDef="objectName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bacnet-device-table.header.device-name' | translate}}</th>
        <td mat-cell *matCellDef="let device">{{device.objectName}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bacnet-device-table.header.description' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{ device.description}}</td>
      </ng-container>

      <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bacnet-device-table.header.vendor-name' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{ device.vendorName}}</td>
      </ng-container>

      <ng-container matColumnDef="modelName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bacnet-device-table.header.model-name' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{ device.modelName}}</td>
      </ng-container>

      <ng-container matColumnDef="contextActions">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
        <td mat-cell *matCellDef="let device" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{device: device}">
          <mat-icon class="clickable">more_vert</mat-icon>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        [class.active]="selectedDevice?.id == row.id"
        *matRowDef="let row; columns: displayedColumns;"
        (click)="deviceSelected2(row)"
      ></tr>
    </table>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-device=device>
  </ng-template>
</mat-menu>
