<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav-content>
    <eis-gateway-bacnet-device-table
      [gatewaySerial]="gatewaySerial"
      (deviceSelectedEventEmitter)="deviceSelectedEventHandler($event)">
    </eis-gateway-bacnet-device-table>

  </mat-sidenav-content>

  <mat-sidenav #drawer [fixedInViewport]="true" [fixedTopGap]="60" [fixedBottomGap]="0" [disableClose]="true"
               position="end">
    <eis-gateway-bacnet-datapoint-configurator-table
      [gatewaySerial]="gatewaySerial"
      [device]="selectedDevice"
      (closeDrawerEvent)="closeDrawerEventHandler($event)"

    >
    </eis-gateway-bacnet-datapoint-configurator-table>

  </mat-sidenav>

</mat-sidenav-container>



