import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {interval, Subscription} from 'rxjs';
import {NotificationService} from '../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {BacnetDevice} from '../../../../domain/bacnet-device';
import {BacnetDeviceService} from '../../../../services/bacnet-device.service';

@Component({
  selector: 'eis-gateway-bacnet-device-table',
  templateUrl: './bacnet-device-table.component.html',
  styleUrls: ['./bacnet-device-table.component.scss']
})
export class BacnetDeviceTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public tenant: string | undefined;

  @Input()
  public location: string | undefined;

  @Output()
  public deviceSelectedEventEmitter = new EventEmitter<any>();

  public devices: BacnetDevice[] = [];
  public devicesDataSource = new MatTableDataSource<BacnetDevice>([]);
  public displayedColumns: string[] = ['hasUndeployedDataPoints', 'objectName', 'deviceId','deviceAddress', 'description', 'vendorName', 'modelName', 'contextActions']

  public selectedDevice: BacnetDevice | undefined;
  public scanningDevices: boolean = false;
  private scanResultSubscription: Subscription | null;
  private scanTimeOutSeconds: number = 20;

  constructor(
    private bacnetService: BacnetDeviceService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.getBacnetDevices()
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit() {
    this.devicesDataSource.sort = this.sort
  }

  scanBacnetDevices() {
    this.scanningDevices = true;

    this.devicesDataSource.data = [];

    this.bacnetService.startScanDevice(this.gatewaySerial!, this.scanTimeOutSeconds).subscribe(result => {
      if (result) {
        this.notificationService.success("bacnet-scan-start-success");
        this.getBacnetDevices();
        this.scanResultSubscription = interval(5 * 1000).subscribe(() => this.getBacnetDevices());
      } else {
        this.notificationService.failure("bacnet-scan-start-error");
      }
    }, (error) => {
      this.notificationService.failure(error.error);
      this.scanningDevices = false
    });
  }

  getBacnetDevices() {
    this.selectedDevice = undefined;
    this.bacnetService.getScanResult(this.gatewaySerial!).subscribe(scanResult => {
      this.devices = scanResult.devices;
      this.devicesDataSource.data = this.devices;
      this.devicesDataSource.sort = this.sort;
      this.scanningDevices = scanResult.scanStatus != "done";
      if (!this.scanningDevices) {
        this.scanResultSubscription?.unsubscribe();
      }
    });
  }

  deviceSelected2(bacnetDevice:BacnetDevice) {
    this.deviceSelectedEventEmitter.emit(bacnetDevice)
  }

  previewUndeployedChanges() {
    //todo
  }

  hasChanges() {
    return true
  }
}
