import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TenantSelectionComponent } from './pages/tenant-selection/tenant-selection.component';
import { SharedModule } from './shared/shared.module';
import { GatewayTableComponent } from './pages/gateway-overview/gateway-table/gateway-table.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AddGatewayDialogComponent } from './pages/gateway-overview/add-gateway-dialog/add-gateway-dialog.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {EonUiInputValueAccessorDirective} from "./components/valueaccessors/eon-ui-input-value-accessor.directive";
import {
  StepIndicatorComponent
} from "./pages/gateway-overview/add-gateway-dialog/step-indicator/step-indicator.component";
import { CheckmarkComponent } from './pages/gateway-overview/add-gateway-dialog/checkmark/checkmark/checkmark.component';
import { FooterComponent } from './components/footer/footer.component';
import { ConnectionCardComponent } from './components/connection-card/connection-card.component';
import { ContentShellComponent } from './components/content-shell/content-shell.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {InitTranslateService} from "./services/init-translate.service";
import {from, Observable} from "rxjs";
import { TenantCardComponent } from './components/tenant-card/tenant-card.component';
import { TenantRadioControlComponent } from './components/tenant-radio-control/tenant-radio-control.component';
import { SplashComponent } from './pages/splash/splash.component';
import { AppShellComponent } from './components/app-shell/app-shell.component';
import {GatewayOverviewComponent} from "./pages/gateway-overview/gateway-overview.component";
import { GatewayDetailsComponent } from './pages/gateway-details/gateway-details.component';
import { AddDeviceDialogComponent } from './pages/gateway-details/modbus/add-device-dialog/add-device-dialog.component';
import {DeviceTableComponent} from "./pages/gateway-details/modbus/device-table/device-table.component";
import {UploadComponent} from './components/upload/upload.component';
import {
  DeleteGatewayDialogComponent
} from './pages/gateway-overview/delete-gateway-dialog/delete-gateway-dialog.component';
import {AuthService} from "./services/auth.service";
import {setAuthService} from "./components/decorators/checkConnectedDecorator";
import { ChangeLocationDialogComponent } from './pages/gateway-overview/change-location-dialog/change-location-dialog.component';
import {DatapointTableComponent} from "./pages/gateway-details/modbus/datapoint-table/datapoint-table.component";
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DeleteDeviceDialogComponent } from './pages/gateway-details/modbus/delete-device-dialog/delete-device-dialog.component';
import {
  EonUiCheckboxValueAccessorDirective
} from "./components/valueaccessors/eon-ui-checkbox-value-accessor.directive";
import { GatewayConfigurationComponent } from './pages/gateway-configuration/gateway-configuration.component';
import { GatewayNetworkConfigComponent } from './pages/gateway-configuration/gateway-network-config/gateway-network-config.component';
import { GatewayComPortConfigComponent } from './pages/gateway-configuration/gateway-com-port-config/gateway-com-port-config.component';
import { LoaderComponent } from './components/loader/loader.component';
import {LoaderService} from "./services/loader.service";
import {setLoaderService} from "./components/decorators/loaderDecorator";
import { ConfirmationDialogComponent } from './pages/gateway-configuration/gateway-network-config/confirmation-dialog/confirmation-dialog.component';
import { GatewayLogLevelConfigComponent } from './pages/gateway-configuration/gateway-log-level-config/gateway-log-level-config.component';
import { EditCSVDialogComponent } from './pages/gateway-details/modbus/edit-csv-dialog/edit-csv-dialog.component';
import { GatewaySoftwareUpdateConfigComponent } from './pages/gateway-configuration/gateway-software-update-config/gateway-software-update-config.component';
import {AuthInterceptor} from './Interceptors/auth-interceptor/auth-interceptor';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import { GatewayOsVersionComponent } from './pages/gateway-overview/gateway-os-version/gateway-os-version.component';
import { GatewayImageVersionComponent } from './pages/gateway-overview/gateway-image-version/gateway-image-version.component';
import { GatewayModbusWriteComponent } from './pages/gateway-modbus-write/gateway-modbus-write.component';
import { MBusDeviceConfiguratorComponent } from './pages/gateway-details/mbus/mbus-device-configurator/m-bus-device-configurator.component';
import {
  MbusDatapointTableComponent
} from './pages/gateway-details/mbus/mbus-datapoint-table/mbus-datapoint-table.component';
import { MbusPreviewDatapointsComponent } from './pages/gateway-details/mbus/mbus-preview-datapoints/mbus-preview-datapoints.component';
import { ResetIpConfigurationDialogComponent } from './pages/gateway-configuration/gateway-network-config/reset-ip-configuration-dialog/reset-ip-configuration-dialog.component';
import { MbusDeviceTableComponent } from './pages/gateway-details/mbus/mbus-device-table/mbus-device-table.component';
import { MbusDatapointConfiguratorTableComponent } from './pages/gateway-details/mbus/mbus-datapoint-configurator-table/mbus-datapoint-configurator-table.component';
import { MbusConfigTableComponent } from './pages/gateway-details/mbus/mbus-config-table/mbus-config-table.component';
import { AddMbusDeviceDialogComponent } from './pages/gateway-details/mbus/add-mbus-device-dialog/add-mbus-device-dialog.component';
import { EditCSVLabelsDialog } from './pages/gateway-details/modbus/edit-csv-labels-dialog/edit-csv-labels-dialog.component';
import { DeleteMbusDeviceDialogComponent } from './pages/gateway-details/mbus/delete-mbus-device-dialog/delete-mbus-device-dialog.component';
import { MbusDetailedDatapointTableComponent } from './pages/gateway-details/mbus/mbus-datapoint-table/mbus-detailed-datapoint-table.component';
import { MbusStopScanDialogComponent } from './pages/gateway-details/mbus/mbus-stop-scan-dialog/mbus-stop-scan-dialog.component';
import { MbusErrorDeviceTableComponent } from './pages/gateway-details/mbus/mbus-error-device-table/mbus-error-device-table.component';
import {
  MbusEditCsvLabelsDialog
} from './pages/gateway-details/mbus/mbus-edit-csv-labels-dialog/mbus-edit-csv-labels-dialog.component';
import {
  MbusEditDeviceLabelsDialogComponent
} from './pages/gateway-details/mbus/mbus-edit-device-labels-dialog/mbus-edit-device-labels-dialog.component';
import { LabelChipsComponent } from './components/label-chips/label-chips.component';
import { DiagnosticsDialogComponent } from './pages/gateway-details/diagnostics-dialog/diagnostics-dialog.component';
import { UnitConversionComponent } from './pages/admin/unit-conversion/unit-conversion.component';
import { AdminPageComponent } from './pages/admin/admin-page/admin-page.component';
import { AddConversionDialogComponent } from './pages/admin/unit-conversion/add-conversion-dialog/add-conversion-dialog.component';
import { DeleteConversionDialogComponent } from './pages/admin/unit-conversion/delete-conversion-dialog/delete-conversion-dialog.component';
import { OsVersionComponent } from './pages/admin/os-version/os-version.component';
import { AddVersionDialogComponent } from './pages/admin/os-version/add-version-dialog/add-version-dialog.component';
import { DeleteVersionDialogComponent } from './pages/admin/os-version/delete-version-dialog/delete-version-dialog.component';
import {
  OpcuaDeviceTableComponent
} from './pages/gateway-details/opcua/opcua-device-table/opcua-device-table.component';
import {
  AddOpcuaDeviceDialogComponent
} from './pages/gateway-details/opcua/add-opcua-device-dialog/add-opcua-device-dialog.component';
import { OpcuaBrowserDialogComponent } from './pages/gateway-details/opcua/opcua-browser-dialog/opcua-browser-dialog.component';
import { OpcuaPreviewChangesComponent } from './pages/gateway-details/opcua/opcua-preview-changes/opcua-preview-changes.component';
import { OpcuaConfigTableComponent } from './pages/gateway-details/opcua/opcua-config-table/opcua-config-table.component';
import {
  OpcuaUnitBrowserDialogComponent
} from './pages/gateway-details/opcua/opcua-unit-browser-dialog/opcua-unit-browser-dialog.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { DeleteOpcuaDeviceDialogComponent } from './pages/gateway-details/opcua/delete-opcua-device-dialog/delete-opcua-device-dialog.component';
import { OpcuaDataPointTableComponent } from './pages/gateway-details/opcua/opcua-data-point-table/opcua-data-point-table.component';
import { OpcuaEditCsvLabelsDialogComponent } from './pages/gateway-details/opcua/opcua-edit-csv-labels-dialog/opcua-edit-csv-labels-dialog.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { BacnetDeviceTableComponent } from './pages/gateway-details/bacnet/bacnet-device-table/bacnet-device-table.component';
import { BacnetDeviceConfiguratorComponent } from './pages/gateway-details/bacnet/bacnet-device-configurator/bacnet-device-configurator.component';
import { BacnetDatapointConfiguratorTableComponent } from './pages/gateway-details/bacnet/bacnet-datapoint-configurator-table/bacnet-datapoint-configurator-table.component';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

export const INITIALIZE_APP =
  (initTranslateService: InitTranslateService) => {
    return (): Promise<any> => {
      return initTranslateService.init$().toPromise();
    };
  };

export const INITIALIZE_CHECKDECORATOR =
  (auth: AuthService) =>
    () => {
      setAuthService(auth)
    };

export const INITIALIZE_LOADERDECORATOR =
  (loaderService: LoaderService) =>
    () => {
      setLoaderService(loaderService)
    };

@NgModule({
  declarations: [
    AppComponent,
    TenantSelectionComponent,
    GatewayTableComponent,
    WelcomeComponent,
    AddGatewayDialogComponent,
    EonUiInputValueAccessorDirective,
    EonUiCheckboxValueAccessorDirective,
    StepIndicatorComponent,
    CheckmarkComponent,
    FooterComponent,
    ConnectionCardComponent,
    ContentShellComponent,
    TenantCardComponent,
    TenantRadioControlComponent,
    SplashComponent,
    AppShellComponent,
    TenantRadioControlComponent,
    GatewayOverviewComponent,
    GatewayDetailsComponent,
    AddDeviceDialogComponent,
    UploadComponent,
    DeviceTableComponent,
    MBusDeviceConfiguratorComponent,
    MbusDatapointTableComponent,
    DatapointTableComponent,
    DeleteGatewayDialogComponent,
    ChangeLocationDialogComponent,
    BreadcrumbComponent,
    DeleteDeviceDialogComponent,
    GatewayConfigurationComponent,
    GatewayNetworkConfigComponent,
    GatewayComPortConfigComponent,
    LoaderComponent,
    ConfirmationDialogComponent,
    GatewayLogLevelConfigComponent,
    EditCSVDialogComponent,
    GatewaySoftwareUpdateConfigComponent,
    GatewayOsVersionComponent,
    GatewayImageVersionComponent,
    GatewayModbusWriteComponent,
    MbusPreviewDatapointsComponent,
    ResetIpConfigurationDialogComponent,
    MbusDeviceTableComponent,
    MbusDatapointConfiguratorTableComponent,
    MbusConfigTableComponent,
    AddMbusDeviceDialogComponent,
    EditCSVLabelsDialog,
    DeleteMbusDeviceDialogComponent,
    MbusDetailedDatapointTableComponent,
    MbusStopScanDialogComponent,
    MbusErrorDeviceTableComponent,
    MbusEditCsvLabelsDialog,
    MbusEditDeviceLabelsDialogComponent,
    LabelChipsComponent,
    DiagnosticsDialogComponent,
    UnitConversionComponent,
    AdminPageComponent,
    AddConversionDialogComponent,
    DeleteConversionDialogComponent,
    OsVersionComponent,
    AddVersionDialogComponent,
    DeleteVersionDialogComponent,
    OpcuaDeviceTableComponent,
    AddOpcuaDeviceDialogComponent,
    OpcuaBrowserDialogComponent,
    OpcuaPreviewChangesComponent,
    OpcuaConfigTableComponent,
    OpcuaUnitBrowserDialogComponent,
    JsonViewerComponent,
    DeleteOpcuaDeviceDialogComponent,
    OpcuaDataPointTableComponent,
    OpcuaEditCsvLabelsDialogComponent,
    AnnouncementsComponent,
    BacnetDeviceTableComponent,
    BacnetDeviceConfiguratorComponent,
    BacnetDatapointConfiguratorTableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: INITIALIZE_APP,
      deps: [InitTranslateService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: INITIALIZE_CHECKDECORATOR,
      deps: [AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: INITIALIZE_LOADERDECORATOR,
      deps: [LoaderService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
