<div class="main-container">
  <div class="header-container">
    <div class="row-space-container margin-bottom-24">
      <div class="device-name">Device Name: {{device?.objectName}}</div>
      <eon-ui-button  [text]="'mbus-datapoint-configurator.button.scan' | translate"
                     icon="search" scheme="red" rank="secondary"
                      [disabled]="scanningDeviceDataPoints"
                      (click)="startScanFOrDatapoints()">

      </eon-ui-button>
    </div>
    <div class="scanning-data-points-warning" *ngIf="scanningDeviceDataPoints">
      <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
      <span>{{'mbus-datapoint-configurator.warning.scan-take-long' | translate}}</span>
    </div>

  </div>
  <div class="table-container" *ngIf="!scanningDeviceDataPoints">
    <table mat-table [dataSource]="dataPointsDataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="toggleAllRows()"
                        [checked]="isAllSelected()" >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="toggleRowSelected(row)"
                        [checked]="isSelected(row)" >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="dataType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dataType">{{'bacnet-datapoint-configurator.header.data-type' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.dataType}}</td>
      </ng-container>

      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'bacnet-datapoint-configurator.header.key' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.key}}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="value">{{'bacnet-datapoint-configurator.header.value' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.value}}</td>
      </ng-container>

      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="unit">{{'bacnet-datapoint-configurator.header.unit' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.unit}}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="description">{{'mbus-datapoint-configurator.header.description' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.description}}</td>
      </ng-container>

      <ng-container matColumnDef="pollingIntervalSeconds">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="pollingIntervalSeconds">{{'bacnet-config-table.header.polling-frequency' | translate}}</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.pollingIntervalSeconds}}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="id">id</th>
        <td mat-cell *matCellDef="let dataPoint">{{dataPoint.id}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
          [class.removed]="isDeleted(row)"
          [class.added]="isNew(row)"
          *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-toolbar>
    <div class="footer-container">
      <eon-ui-button [text]="'general.close' | translate"
                     icon="close" scheme="red" rank="secondary"
                     (click)="closeDrawer()"></eon-ui-button>


        <eon-ui-button [text]="'general.save' | translate"
                       icon="radio_checkbox_tick" scheme="red"
                       (click)="saveChanges()"></eon-ui-button>

    </div>
  </mat-toolbar>



</div>



